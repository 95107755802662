import React from 'react'
import construction from '../design/construction.png';

function CommunityProgressions() {
    return (
        //<>Community Progressions</>
        <img src={construction} style={{ maxWidth: '100%', maxHeight: '400px' }} alt="construction" />
    )
}

export default CommunityProgressions